@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "Myriad-Pro-Regular";
		font-style: normal;
		font-weight: normal;
		src: local("Myriad Pro Regular"),
			url("../src/assets/font/myriad-pro/MYRIADPRO-REGULAR.woff") format("woff");
	}

	@font-face {
		font-family: "Myriad-Pro-Semibold";
		font-style: normal;
		font-weight: normal;
		src: local("Myriad Pro Semibold"),
			url("../src/assets/font/myriad-pro/MYRIADPRO-SEMIBOLD.woff") format("woff");
	}

	@font-face {
		font-family: "Myriad-Pro-Bold";
		font-style: normal;
		font-weight: normal;
		src: local("Myriad Pro Bold"), url("../src/assets/font/myriad-pro/MYRIADPRO-BOLD.woff") format("woff");
	}
}

@layer utilities {
  .clip{
    /* overflow: hidden; */
    position: relative;
  }
  /* .clip::after {
    content: '';
    position: absolute;
    left: -10%;
    right: -10%;
    height: 100px;
    border-radius: 50%;
    bottom: -30px;
    @apply bg-sgrey-50
  } */

  .clip::after{
    content: '';
    position: absolute;
    height: 150px;
    bottom: 0px;
    right: -10%;
    left: -10%;
    z-index: 1;
    -webkit-clip-path: ellipse(50% 50% at 50% 100%);
    clip-path: ellipse(50% 50% at 50% 100%);
    @apply bg-sgrey-50
  }
}
@layer components {
  .stext-heading-top {
    @apply text-[28px] font-myriadProBold md:text-[39px] leading-[31px] md:leading-[48px] text-sgrey-900;
  }
  .stext-heading {
    @apply text-2xl font-myriadProBold md:text-[35px] leading-[29px] md:leading-[42px] text-sgrey-900;
  }
  .stext-base {
    @apply text-sm font-myriadProRegular;
  }
  .bg-gradient {
    /* background: linear-gradient( theme(colors.sprimary-200)); */
    /* background: linear-gradient(360deg, #8567E7 -61.82%, #371B63 86.35%); */
    @apply bg-gradient-to-b from-sprimary-200 to-sprimary;
  }

  .layout-container {
    @apply px-6 md:px-[51px] lg:px-[97px]
  }

  .error {
    /* I use this for debugging */
    @apply border border-red-700
  }
}


/* For Webkit-based browsers (Chrome, Safari and Opera) */
.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.myriad-regular {
  font-family: Myriad-Pro-Regular;
  font-weight: 400;
}

.myriad-semibold {
  font-family: Myriad-Pro-Semibold;
  font-weight: 600,
}

.myriad-bold {
  font-family: Myriad-Pro-Bold;
  font-weight: 700;
}

.btn-bg {
  background: linear-gradient(252.96deg, #650EBB 14.09%, #8567E7 113.24%);
}

html {
	scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}


button, div {
  -webkit-tap-highlight-color: transparent;
}

.anim_fadeIn {
  animation: fade-in 100ms linear both;
}

@keyframes fade-in {
  from {
   transform: translateY(5px) translateX(-50%) scale(0.4);
    opacity: 0;
    left: 50%;
  }
  to{
  transform: translateY(0px) translateX(-50%) scale(1);
  opacity: 1;
  left: 50%;
  }
}

.slide_in {
  animation: slide-in 200ms linear;
}

@keyframes slide-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide_in_rtl {
  animation: slide-in-rtl 500ms linear;
}

@keyframes slide-in-rtl {
  0% {
    transform: translateX(-15px); 
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.sanimate-fade-in {
  animation: sfade-in 500ms linear;
}

@keyframes sfade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
